<template>
  <div class="flex flex-row items-center space-x-4">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="flex flex-row space-x-2 items-center cursor-pointer"
      @click="selectedOption = item.key"
    >
      <aero-pt-icon
        :name="item.key == selectedOption ? `radio-on` : `radio-off`"
        :customClass="'h-[19px]'"
      />
      <aero-pt-normal-text
        :color="`${
          item.key == selectedOption ? 'text-black' : 'text-neutral_grey_1'
        }`"
      >
        {{ item.label }}
      </aero-pt-normal-text>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import AeroPtIcon from "../AeroPtIcon";
import { AeroPtNormalText } from "../AeroPtTypography";

export default defineComponent({
  components: {
    AeroPtIcon,
    AeroPtNormalText,
  },
  props: {
    customClass: {
      type: String,
      default: "",
    },
    items: {
      type: Array as () => {
        label: string;
        key: string;
      }[],
      default: () => [],
    },
  },
  emits: ["update:modelValue"],
  name: "AeroPtRadioGroup",
  setup(props, context) {
    const selectedOption = ref("");

    onMounted(() => {
      if (props.items.length) {
        selectedOption.value = props.items[0].key;
      }
    });

    watch(selectedOption, () => {
      context.emit("update:modelValue", selectedOption.value);
    });

    return {
      selectedOption,
    };
  },
});
</script>
