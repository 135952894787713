import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col bg-white shadow-custom_1 py-4 px-4 rounded-[24px] space-y-3" }
const _hoisted_2 = { class: "col-span-3 grid grid-cols-2 border-[1px] border-[#E1E7EE] rounded-[12px]" }
const _hoisted_3 = { class: "col-span-1 py-3 px-3 flex flex-col space-y-1 justify-start border-r-[1px] border-[#E1E7EE] relative pl-5" }
const _hoisted_4 = { class: "flex flex-col space-y-[2px]" }
const _hoisted_5 = { class: "col-span-1 py-3 px-3 flex flex-col space-y-1 justify-start pl-5" }
const _hoisted_6 = { class: "flex flex-col space-y-[2px]" }
const _hoisted_7 = { class: "col-span-3 grid grid-cols-2 border-[1px] border-[#E1E7EE] rounded-[12px]" }
const _hoisted_8 = { class: "col-span-1 py-3 px-3 flex flex-col space-y-1 justify-start border-r-[1px] border-[#E1E7EE]" }
const _hoisted_9 = { class: "flex flex-col space-y-[2px]" }
const _hoisted_10 = { class: "col-span-1 py-3 px-3 flex flex-col space-y-1 justify-start pl-5" }
const _hoisted_11 = { class: "flex flex-col space-y-[2px]" }
const _hoisted_12 = { class: "col-span-1 grid grid-cols-1 border-[1px] border-[#E1E7EE] rounded-[12px]" }
const _hoisted_13 = { class: "col-span-1 py-3 px-3 flex flex-col space-y-1 justify-start pl-5" }
const _hoisted_14 = { class: "flex flex-col space-y-[2px]" }
const _hoisted_15 = { class: "w-full flex flex-row items-center justify-center pt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_aero_pt_radio_group = _resolveComponent("aero-pt-radio-group")!
  const _component_aero_pt_normal_text = _resolveComponent("aero-pt-normal-text")!
  const _component_aero_pt_header_text = _resolveComponent("aero-pt-header-text")!
  const _component_aero_pt_icon = _resolveComponent("aero-pt-icon")!
  const _component_aero_pt_button = _resolveComponent("aero-pt-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_aero_pt_radio_group, {
      items: _ctx.flightOptions,
      modelValue: _ctx.flightSearchRequest.option,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.flightSearchRequest.option) = $event))
    }, null, 8, ["items", "modelValue"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.flightSearchRequest.flights, (flight, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "w-full grid grid-cols-7 gap-3",
        key: index
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_aero_pt_normal_text, null, {
              default: _withCtx(() => [
                _createTextVNode(" From ")
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_aero_pt_header_text, { class: "cursor-text" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(flight.from.location_name), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_aero_pt_normal_text, { class: "!line-clamp-1" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(flight.from.airport_name), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            (_ctx.flightSearchRequest.option == 'RoundTrip')
              ? (_openBlock(), _createBlock(_component_aero_pt_icon, {
                  key: 0,
                  name: 'round-trip',
                  customClass: 'h-[65px]',
                  class: "absolute right-[-10%] bottom-[-2%]"
                }))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_aero_pt_normal_text, null, {
              default: _withCtx(() => [
                _createTextVNode(" To ")
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_aero_pt_header_text, { class: "cursor-text" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(flight.to.location_name), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_aero_pt_normal_text, { class: "!line-clamp-1" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(flight.to.airport_name), 1)
                ]),
                _: 2
              }, 1024)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_aero_pt_normal_text, null, {
              default: _withCtx(() => [
                _createTextVNode(" Depart ")
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_aero_pt_header_text, { class: "cursor-text" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.Logic.Common.fomartDate(flight.departure_date, "D MMMM, YYYY")), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_aero_pt_normal_text, { class: "!line-clamp-1" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.Logic.Common.fomartDate(flight.departure_date, "dddd")), 1)
                ]),
                _: 2
              }, 1024)
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_aero_pt_normal_text, null, {
              default: _withCtx(() => [
                _createTextVNode(" Arrive ")
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_aero_pt_header_text, { class: "cursor-text" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.Logic.Common.fomartDate(flight.arrival_date, "D MMMM, YYYY")), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_aero_pt_normal_text, { class: "!line-clamp-1" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.Logic.Common.fomartDate(flight.arrival_date, "dddd")), 1)
                ]),
                _: 2
              }, 1024)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_aero_pt_normal_text, null, {
              default: _withCtx(() => [
                _createTextVNode(" Passager ")
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_aero_pt_header_text, { class: "cursor-text" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(flight.passengers.total) + " Guest" + _toDisplayString(flight.passengers.total > 1 ? "s" : ""), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_aero_pt_normal_text, { class: "!line-clamp-1" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(flight.bin_class) + " class ", 1)
                ]),
                _: 2
              }, 1024)
            ])
          ])
        ])
      ]))
    }), 128)),
    _createElementVNode("div", _hoisted_15, [
      _createVNode(_component_aero_pt_button, {
        variant: 'solid-blue',
        padding: 'py-3 px-8'
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Search Ticket ")
        ]),
        _: 1
      })
    ])
  ]))
}