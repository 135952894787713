import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex w-full flex-col space-y-2" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-row relative"
}
const _hoisted_3 = ["accept", "multiple"]
const _hoisted_4 = {
  key: 1,
  class: "rounded flex flex-row items-center justify-start relative space-x-2 px-1 py-4 bg-grayBackground border-dashed"
}
const _hoisted_5 = ["accept", "multiple"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_aero_pt_icon = _resolveComponent("aero-pt-icon")!
  const _component_aero_pt_normal_text = _resolveComponent("aero-pt-normal-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isWrapper)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("input", {
            type: "file",
            style: {"opacity":"0","width":"100%","height":"100%","left":"0","overflow":"hidden","position":"absolute","z-index":"10"},
            accept: _ctx.accept,
            multiple: _ctx.isMultiple,
            onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.uploadHandler && _ctx.uploadHandler(...args)))
          }, null, 40, _hoisted_3),
          _renderSlot(_ctx.$slots, "content")
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("input", {
            type: "file",
            style: {"opacity":"0","width":"100%","height":"100%","left":"0","overflow":"hidden","position":"absolute","z-index":"10"},
            accept: _ctx.accept,
            multiple: _ctx.isMultiple,
            onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.uploadHandler && _ctx.uploadHandler(...args)))
          }, null, 40, _hoisted_5),
          _createVNode(_component_aero_pt_icon, {
            name: `${_ctx.iconName}`,
            customClass: 'h-[15px]'
          }, null, 8, ["name"]),
          _createVNode(_component_aero_pt_normal_text, {
            color: "text-paragraphTextLight",
            customClass: "w-full text-left line-clamp-1"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.selectedFileName != "" ? _ctx.selectedFileName : _ctx.placeholder), 1)
            ]),
            _: 1
          })
        ]))
  ]))
}