import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex w-full flex-col relative" }
const _hoisted_2 = ["tabindex"]
const _hoisted_3 = ["placeholder", "disabled", "type"]
const _hoisted_4 = {
  key: 1,
  class: "w-full flex flex-row pt-1 justify-start"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_aero_pt_normal_text = _resolveComponent("aero-pt-normal-text")!
  const _component_aero_pt_icon = _resolveComponent("aero-pt-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hasTitle)
      ? (_openBlock(), _createBlock(_component_aero_pt_normal_text, {
          key: 0,
          customClass: "!pb-2"
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "title")
          ]),
          _: 3
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "w-full flex flex-row items-center",
      tabindex: _ctx.tabIndex,
      onFocus: _cache[5] || (_cache[5] = ($event: any) => (_ctx.isFocused = true)),
      onBlur: _cache[6] || (_cache[6] = ($event: any) => (_ctx.isFocused = false))
    }, [
      _renderSlot(_ctx.$slots, "outer-prefix"),
      _createElementVNode("div", {
        class: _normalizeClass(`flew-grow w-full space-x-1 flex-row flex items-center justify-between ${_ctx.padding} ${_ctx.customClass}  bg-grayBackground rounded ${
          _ctx.isFocused ? 'border-primaryOrange border-[1px]' : ''
        } ${
          _ctx.validationStatus == false ? '!border-primaryRed !border-[1px]' : ''
        }`)
      }, [
        _renderSlot(_ctx.$slots, "inner-prefix"),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.content) = $event)),
          placeholder: _ctx.placeholder,
          onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isFocused = true)),
          onBlur: _cache[2] || (_cache[2] = ($event: any) => {
            _ctx.isFocused = false;
            _ctx.checkValidation();
          }),
          onKeypress: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.isNumber && _ctx.isNumber(...args))),
          disabled: _ctx.disabled,
          type: _ctx.fieldType,
          class: _normalizeClass(` text-lightBlack flex-grow bg-transparent placeholder-primaryGray focus input w-full focus:outline-none  `)
        }, null, 40, _hoisted_3), [
          [_vModelDynamic, _ctx.content]
        ]),
        _renderSlot(_ctx.$slots, "inner-suffix"),
        (_ctx.type == 'password')
          ? (_openBlock(), _createBlock(_component_aero_pt_icon, {
              key: 0,
              name: `${_ctx.fieldType == 'password' ? 'show' : 'hide'}`,
              customClass: `${_ctx.fieldType == 'password' ? 'h-[12px]' : 'h-[15px]'}`,
              onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (
            _ctx.fieldType == 'password'
              ? (_ctx.fieldType = 'text')
              : (_ctx.fieldType = 'password')
          ), ["stop"]))
            }, null, 8, ["name", "customClass"]))
          : _createCommentVNode("", true)
      ], 2),
      _renderSlot(_ctx.$slots, "outer-suffix")
    ], 40, _hoisted_2),
    (!_ctx.validationStatus)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_aero_pt_normal_text, {
            customClass: ' text-left',
            color: `text-primaryRed`
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.errorMessage), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}