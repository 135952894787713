<template>
  <metainfo>
    <template #title="{ content }">{{
      content ? `${content} | Aeropoint` : `Aeropoint`
    }}</template>
  </metainfo>
  <AppLayout>
    <router-view />
  </AppLayout>
</template>
<script lang="ts">
import { useMeta } from "vue-meta";

export default {
  setup(): unknown {
    useMeta({
      title: "Home",
      htmlAttrs: { lang: "en", amp: true },
    });

    return {};
  },
};
</script>
<style>
body,
html {
  -webkit-tap-highlight-color: transparent;
  background: #f4f7f9;
}
</style>
