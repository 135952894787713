import currency from 'currency.js'
import moment from 'moment'
import { CombinedError } from 'urql'
import {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteLocationNormalizedLoaded,
  Router,
} from 'vue-router'
import { Logic } from '..'
import { FetchRule, LoaderSetup } from '../types/common'
import { reactive } from 'vue'

export default class Common {
  public router: Router | undefined = undefined

  private initiatedAudios: string[] = []

  public callIdentity: string | undefined = undefined

  public callContactList: any = {}

  public adInitialized = false

  public abbreviateNumber = (number: number) => {
    return Intl.NumberFormat('en-US', {
      notation: 'compact',
      maximumFractionDigits: 1,
    }).format(number)
  }

  public countDownToAction = (periodInSecond: number, callback: Function) => {
    let start = periodInSecond
    // @ts-ignore
    window.globalTimer = setInterval(() => {
      if (start <= 0) {
        // @ts-ignore
        clearInterval(window.globalTimer)
        callback()
      } else {
        start--
      }
    }, 1000)
  }

  public clearTimer = () => {
    // @ts-ignore
    clearInterval(window.globalTimer)
  }

  /*
   * Javascript implementation of Fisher-Yates shuffle algorithm
   * http://stackoverflow.com/questions/2450954/how-to-randomize-a-javascript-array
   */
  public shuffleArray(array: any[]) {
    let currentIndex = array.length
    let temporaryValue
    let randomIndex

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex -= 1

      // And swap it with the current element.
      temporaryValue = array[currentIndex]
      array[currentIndex] = array[randomIndex]
      array[randomIndex] = temporaryValue
    }

    return array
  }

  public groupArrayBy = (xs: any[], key: string) => {
    return xs.reduce(function (rv, x) {
      ;(rv[x[key]] = rv[x[key]] || []).push(x)
      return rv
    }, {})
  }

  public route: RouteLocationNormalizedLoaded | undefined = undefined

  public apiUrl: string | undefined = undefined

  public watchInterval: number | undefined = undefined

  public loadingState = false

  public showBottomNav = false

  public SetRouter = (router: Router) => {
    this.router = router
  }

  public SetRoute = (route: RouteLocationNormalizedLoaded) => {
    this.route = route
  }

  public loaderSetup: LoaderSetup = reactive({
    show: false,
    useModal: false,
    hasError: false,
    loading: false,
    message: '',
    ctaText: '',
    ctaFunction: () => {},
    icon: 'success-thumb',
    title: '',
  })

  public SetApiUrl = (apiUrl: string) => {
    this.apiUrl = apiUrl
  }

  public GoToRoute = (path: string) => {
    this.router?.push(path)
  }

  public goBack = () => {
    const ignoreBackRoute = this.route?.query.ignoreBackRoute
      ? this.route.query.ignoreBackRoute.toString()
      : null
    const routeMiddlewares: any = this.route?.meta.middlewares
    const goBackRoute = routeMiddlewares?.goBackRoute
    if (typeof goBackRoute == 'function' && !ignoreBackRoute) {
      this.GoToRoute(goBackRoute())
    } else if (typeof goBackRoute == 'string' && !ignoreBackRoute) {
      this.GoToRoute(goBackRoute)
    } else {
      window.history.length > 1 ? this.router?.go(-1) : this.router?.push('/')
    }
  }

  public showSuccess = (
    message: string,
    action: any = undefined,
    actionLabel = 'Got it!',
    point = 0,
    icon:
      | 'success-thumb'
      | 'challenge-joined'
      | 'incoming-email' = 'success-thumb',
  ) => {
    // Implement success action
  }

  public showError = (
    error: CombinedError | undefined,
    fallbackMsg = '',
    ctaText = 'Close',
    ctaAction: Function | undefined = undefined,
    preventClose = false,
    closeAction: Function | undefined = undefined,
  ) => {
    const message = error?.graphQLErrors[0].message
    // Implement error action
  }

  public copytext = (text: string) => {
    const el = document.createElement('textarea')
    el.value = text
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
  }

  public getLabel = (data: any, key: string) => {
    const thisData = data.filter((Option: any) => {
      return Option.key == key
    })

    return thisData.length > 0 ? thisData[0].value : ''
  }

  public showLoader = (loaderSetup: LoaderSetup) => {
    this.loaderSetup = loaderSetup
  }

  public makeid = (length: number) => {
    var result = ''
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  public getFormatedPeriod = (durationInMin: string) => {
    const duration = parseFloat(durationInMin)

    if (duration >= 60) {
      const hours = (duration / 60).toFixed(1)
      return `${parseFloat(hours)}${parseFloat(hours) > 1 ? 'hrs' : 'hr'}`
    } else {
      return `${duration}mins`
    }
  }

  public hideLoader = () => {
    const Loader: LoaderSetup = {
      show: false,
      useModal: false,
      loading: false,
    }
    this.loaderSetup = Loader
  }

  public globalParameters = reactive<{
    currency: string
  }>({
    currency: 'ngn',
  })

  public momentInstance = moment

  public convertMinutesToHoursAndMinutes(
    minutes: number,
  ): { hours: number; minutes: number } {
    const hours = Math.floor(minutes / 60)
    const remainingMinutes = minutes % 60

    return {
      hours: hours,
      minutes: remainingMinutes,
    }
  }

  public convertToSlug = (text: string) => {
    return text
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '')
  }

  public groupArray(array: any[], num: number) {
    const group = []

    for (let i = 0; i < array.length; i += num) {
      group.push(array.slice(i, i + num))
    }

    return group
  }

  public convertToMoney = (
    float: any,
    withZeros = true,
    currencyType = 'ngn',
    withSymbol = true,
    customSymbol = '',
  ) => {
    let currencySymbol = ''
    if (currencyType == 'usd') {
      currencySymbol = '$ '
    } else if (currencyType == 'ngn') {
      currencySymbol = '₦'
    }
    if (!withSymbol) {
      currencySymbol = ''
    }

    if (customSymbol) {
      currencySymbol = customSymbol
    }
    if (withZeros) {
      return currency(float, {
        separator: ',',
        symbol: currencySymbol,
      }).format()
    } else {
      return (
        currencySymbol +
        new Intl.NumberFormat().format(parseFloat(parseFloat(float).toFixed(2)))
      )
    }
  }

  private isString = (x: any) => {
    return Object.prototype.toString.call(x) === '[object String]'
  }

  public searchArray = (arr: any[], searchKey: string) => {
    return arr.filter((obj) => {
      return Object.keys(obj).some((key) => {
        return this.isString(obj[key]) ? obj[key].includes(searchKey) : false
      })
    })
  }

  public debounce = (
    method = () => {
      //
    },
    delay = 500,
  ) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (typeof window.LIT !== 'undefined') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      clearTimeout(window.LIT)
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    window.LIT = setTimeout(() => {
      method()
    }, delay)
  }

  public watchProperty = (objectToWatch: any, objectToUpdate: any) => {
    let upatedValue = (this as any)[`${objectToWatch}`]
    const watchAction = () => {
      upatedValue = (this as any)[`${objectToWatch}`]
      if (objectToUpdate) {
        objectToUpdate.value = upatedValue
      }
      this.watchInterval = window.requestAnimationFrame(watchAction)
    }

    watchAction()
  }

  public stopWatchAction = () => {
    if (this.watchInterval != undefined) {
      window.cancelAnimationFrame(this.watchInterval)
    }
  }

  public fetchFile = (url: string) => {
    return new Promise(function (resolve, reject) {
      // Get file name from url.
      const xhr = new XMLHttpRequest()
      xhr.responseType = 'blob'
      xhr.onload = function () {
        resolve(xhr)
      }
      xhr.onerror = reject
      xhr.open('GET', url)
      xhr.send()
    }).then(function (xhr: any) {
      const filename = url.substring(url.lastIndexOf('/') + 1).split('?')[0]
      return {
        filename,
        blobFile: xhr.response,
      }
    })
  }

  public blobToBase64 = (blob: Blob) => {
    return new Promise((resolve, _) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    })
  }

  public base64ToBlob = (url: string) => {
    return fetch(url)
      .then((res) => res.blob())
      .then((data) => {
        return data
      })
  }

  public b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data)
    const byteArrays: any[] = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    const blob = new Blob(byteArrays, { type: contentType })
    return blob
  }

  public fomartDate = (date: string, format: string) => {
    return moment(date).format(format)
  }

  public countDownTime = (endTime: string) => {
    return moment(moment(endTime).diff(moment.now())).format('mm:ss')
  }

  public timeFromNow = (time: string) => {
    return moment(time).fromNow()
  }

  public updatedData = (oldData: any, newData: any) => {
    if (oldData != undefined && newData != undefined) {
      return { ...oldData, ...newData }
    }
    return oldData
  }

  public preFetchRouteData = (
    routeTo: RouteLocationNormalized,
    next: NavigationGuardNext,
    _routeFrom: RouteLocationNormalized,
  ) => {
    const allActions: Promise<any>[] = []
    if (this.loaderSetup.loading) {
      return
    }

    const routeMiddlewares: any = routeTo.meta.middlewares

    // handle fetchRules

    const fetchRules: FetchRule[] = routeMiddlewares.fetchRules

    // page layout
    const layout: any = routeTo.meta?.layout
    if (layout == 'Dashboard') {
      this.showBottomNav = true
    } else {
      this.showBottomNav = false
    }

    let BreakException = {}

    try {
      fetchRules?.forEach((rule) => {
        if (rule.requireAuth) {
          if (!Logic.Auth.AuthUser) {
            window.location.href = '/auth/login'

            throw BreakException
          }
        }

        let addRuleToRequest = true

        if (rule.condition) {
          switch (rule.condition.routeSearchItem) {
            case 'fullPath':
              addRuleToRequest = routeTo['fullPath'].includes(
                rule.condition.searchQuery,
              )
              break
            case 'params':
              addRuleToRequest = routeTo['params'][rule.condition.searchQuery]
                ? true
                : false
              break
            case 'query':
              addRuleToRequest = routeTo['query'][rule.condition.searchQuery]
                ? true
                : false
              break
            default:
              break
          }
        }

        if (addRuleToRequest) {
          // @ts-ignore
          const domain = Logic[rule.domain]

          let fetchData = false

          if (domain[rule.property] == undefined) {
            fetchData = true
          }

          if (
            typeof rule.ignoreProperty == 'function' &&
            rule.ignoreProperty()
          ) {
            fetchData = true
          }

          if (rule.ignoreProperty) {
            fetchData = true
          }

          if (rule.subProperty) {
            if (domain[rule.property][rule.subProperty] == undefined) {
              fetchData = true
            }
          }

          if (fetchData) {
            allActions.push(
              new Promise((resolve) => {
                const routeId = []
                if (rule.useRouteId) {
                  routeId.push(routeTo.params.id.toString())
                }

                if (rule.useRouteQuery) {
                  const allQueries: any[] = []
                  rule.queries?.forEach((item) => {
                    allQueries.unshift(routeTo.query[item])
                  })
                  rule.params.unshift(...allQueries)
                }

                // update userid
                rule.params.forEach((param) => {
                  if (typeof param === 'object') {
                    if (param.where) {
                      param.where.forEach((item: any) => {
                        if (item.field == 'user.id' || item.field == 'userId') {
                          item.value = Logic.Auth.AuthUser?.id
                        }
                      })
                    }
                  }
                })

                const allParameter = [...new Set(rule.params)]

                if (routeId.length) {
                  allParameter.unshift(...routeId)
                }

                const request = domain[rule.method](...allParameter)

                request?.then((value: any) => {
                  resolve(value)
                })
              }),
            )
          } else {
            if (rule.silentUpdate) {
              // run in silence
              if (rule.useRouteId) {
                rule.params.unshift(routeTo.params.id.toString())
              }
              if (rule.useRouteQuery) {
                const allQueries: any[] = []
                rule.queries?.forEach((item) => {
                  allQueries.unshift(routeTo.query[item])
                })
                rule.params.unshift(...allQueries)
              }
              rule.params = [...new Set(rule.params)]
              setTimeout(() => {
                domain[rule.method](...rule.params)
              }, 1000)
            }
          }
        }
      })
    } catch (error) {
      if (error !== BreakException) throw error
    }

    // save user activities

    if (routeMiddlewares.tracking_data) {
      const trackingData: any = routeMiddlewares.tracking_data
      // Logic.User.SaveUserActivity(
      //   trackingData.lable,
      //   'page_view',
      //   undefined,
      //   trackingData,
      // )
    }

    if (allActions.length > 0) {
      // this.showLoader({
      //   loading: true,
      //   show: false,
      // })

      Promise.all(allActions).then(() => {
        this.hideLoader()
        return next()
      })
    } else {
      this.hideLoader()
      return next()
    }
  }
}
