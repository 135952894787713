import AppLayout from './layouts/AppLayout.vue'
import { createApp } from 'vue/dist/vue.esm-bundler.js'
import App from './App.vue'
import { createRouter, createWebHistory, useRoute, useRouter } from 'vue-router'
import routes from './router/routes'
import { createMetaManager } from 'vue-meta'

// You can disable this if you dont want TailwindCss
import './assets/app.css'

// UI component css style
import '@aeropt/ui-components/dist/library.min.css'
import { SetFrontendLogic } from '@aeropt/ui-components'
// Frontend logic module
import { Logic } from '@aeropt/logic'

const router = Promise.all(routes).then((routes) => {
  const router = createRouter({
    history: createWebHistory(),
    routes,
  })

  // router.beforeEach((to, from, next) => {
  // 	if (!to.meta.middlewares) {
  // 		return next();
  // 	}
  // 	const middlewares: any = to.meta.middlewares;
  // 	Object.keys(middlewares).forEach((middleware) => {
  // 		middlewares[middleware]({ to, from, next });
  // 	});
  // 	return next();
  // });

  return router
})

const init = async () => {
  createApp({
    components: {
      App,
    },
    setup() {
      const router: any = useRouter()
      const route: any = useRoute()

      // Set routers
      Logic.Common.SetRouter(router)
      Logic.Common.SetRoute(route)

      // Set UI frontend logic
      SetFrontendLogic(Logic)

      // Set app version
      localStorage.setItem('app_version', '1.13')

      // Set app url
      Logic.Common.SetApiUrl(
        process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : '',
      )

      return {}
    },
  })
    .component('AppLayout', AppLayout)
    .use(await router)
    .use(createMetaManager())
    .mount('#app')
}

init()
