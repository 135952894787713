import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "flex w-full flex-col space-y-2" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "flex flex-row space-x-2 items-center justify-between w-full" }
const _hoisted_4 = { class: "flex flex-row space-x-2 items-center justify-start" }
const _hoisted_5 = { class: "h-full flex items-start justify-center" }
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_aero_pt_normal_text = _resolveComponent("aero-pt-normal-text")!
  const _component_aero_pt_icon = _resolveComponent("aero-pt-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, (option, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        onClick: _withModifiers(($event: any) => ($setup.selectedOption = option.key), ["stop"]),
        class: "flex w-full flex-row items-center justify-between cursor-pointer py-3 border-b-[1px] border-gray-300"
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, [
              _createElementVNode("span", {
                class: _normalizeClass(`h-[11px] w-[11px] rounded-full ${
                $setup.selectedOption == option.key
                  ? 'bg-primaryOrange'
                  : 'bg-[#D9D9D9]'
              }`)
              }, null, 2)
            ]),
            _createVNode(_component_aero_pt_normal_text, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(option.value), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          (option.isImage != true)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (!option.hasIcon)
                  ? (_openBlock(), _createBlock(_component_aero_pt_normal_text, {
                      key: 0,
                      "custom-class": "!font-semibold",
                      color: "!text-primaryOrange"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(option.extras), 1)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                (option.hasIcon && option.extras)
                  ? (_openBlock(), _createBlock(_component_aero_pt_icon, {
                      key: 1,
                      name: option.extras || '',
                      "custom-class": `${
              option.extras == 'mastercard' ? 'h-[16px]' : 'h-[11px]'
            }`
                    }, null, 8, ["name", "custom-class"]))
                  : _createCommentVNode("", true)
              ], 64))
            : (_openBlock(), _createElementBlock("img", {
                key: 1,
                src: `${option.extras}`,
                class: "h-[25px] w-auto"
              }, null, 8, _hoisted_6))
        ])
      ], 8, _hoisted_2))
    }), 128))
  ]))
}