import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`w-full col-span-full ${_ctx.customClass} `)
  }, [
    _createVNode(_component_swiper, {
      "slides-per-view": _ctx.slidePerView,
      "space-between": _ctx.spaceBetween,
      onSwiper: _ctx.onSwiper,
      freeMode: _ctx.freeMode,
      modules: _ctx.modules,
      onSlideChange: _ctx.onSlideChange,
      class: _normalizeClass(_ctx.swiperClass),
      direction: _ctx.direction
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    }, 8, ["slides-per-view", "space-between", "onSwiper", "freeMode", "modules", "onSlideChange", "class", "direction"])
  ], 2))
}