import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex flex-row w-full space-x-1 px-1 py-1 bg-white bg-opacity-20 rounded-[999px] justify-between" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_aero_pt_normal_text = _resolveComponent("aero-pt-normal-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass(`rounded-[999px] items-center justify-center flex flex-row lg:!px-5 mdlg:!px-5 px-4 py-2 cursor-pointer ${
        _ctx.currentSelectedItem == item.key ? 'bg-white' : ''
      }`),
        onClick: ($event: any) => (_ctx.currentSelectedItem = item.key)
      }, [
        _createVNode(_component_aero_pt_normal_text, {
          color: `${
          _ctx.currentSelectedItem == item.key ? 'text-primary_blue' : 'text-white'
        }`,
          customClass: `${
          _ctx.currentSelectedItem == item.key ? '!font-semibold' : ''
        }`
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.name), 1)
          ]),
          _: 2
        }, 1032, ["color", "customClass"])
      ], 10, _hoisted_2))
    }), 128))
  ]))
}