<template>
  <!-- Top Bar -->
  <div class="w-full flex flex-row fixed top-0 left-0 !z-[100]">
    <aero-pt-top-nav />
  </div>
  <!-- Content -->
  <div class="w-full">
    <slot />
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import { AeroPtTopNav } from "@aeropt/ui-components";

export default defineComponent({
  name: "HomeLayout",
  components: {
    AeroPtTopNav,
  },
  setup() {
    const topBarColorTheme = ref("transparent");

    return {
      topBarColorTheme,
    };
  },
});
</script>
