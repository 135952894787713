<template>
  <div
    class="w-full flex flex-col bg-white shadow-custom_1 py-4 px-4 rounded-[24px] space-y-3"
  >
    <!-- Flight options -->
    <aero-pt-radio-group
      :items="flightOptions"
      v-model="flightSearchRequest.option"
    />

    <!-- All flights -->
    <div
      class="w-full grid grid-cols-7 gap-3"
      v-for="(flight, index) in flightSearchRequest.flights"
      :key="index"
    >
      <div
        class="col-span-3 grid grid-cols-2 border-[1px] border-[#E1E7EE] rounded-[12px]"
      >
        <!-- From -->
        <div
          class="col-span-1 py-3 px-3 flex flex-col space-y-1 justify-start border-r-[1px] border-[#E1E7EE] relative pl-5"
        >
          <aero-pt-normal-text> From </aero-pt-normal-text>
          <div class="flex flex-col space-y-[2px]">
            <aero-pt-header-text class="cursor-text">
              {{ flight.from.location_name }}
            </aero-pt-header-text>
            <aero-pt-normal-text class="!line-clamp-1">
              {{ flight.from.airport_name }}
            </aero-pt-normal-text>
          </div>

          <aero-pt-icon
            v-if="flightSearchRequest.option == 'RoundTrip'"
            :name="'round-trip'"
            :customClass="'h-[65px]'"
            class="absolute right-[-10%] bottom-[-2%]"
          />
        </div>

        <!-- To -->
        <div
          class="col-span-1 py-3 px-3 flex flex-col space-y-1 justify-start pl-5"
        >
          <aero-pt-normal-text> To </aero-pt-normal-text>
          <div class="flex flex-col space-y-[2px]">
            <aero-pt-header-text class="cursor-text">
              {{ flight.to.location_name }}
            </aero-pt-header-text>
            <aero-pt-normal-text class="!line-clamp-1">
              {{ flight.to.airport_name }}
            </aero-pt-normal-text>
          </div>
        </div>
      </div>

      <!-- departure and arrival date -->
      <div
        class="col-span-3 grid grid-cols-2 border-[1px] border-[#E1E7EE] rounded-[12px]"
      >
        <!-- Departure -->
        <div
          class="col-span-1 py-3 px-3 flex flex-col space-y-1 justify-start border-r-[1px] border-[#E1E7EE]"
        >
          <aero-pt-normal-text> Depart </aero-pt-normal-text>
          <div class="flex flex-col space-y-[2px]">
            <aero-pt-header-text class="cursor-text">
              {{
                Logic.Common.fomartDate(flight.departure_date, "D MMMM, YYYY")
              }}
            </aero-pt-header-text>
            <aero-pt-normal-text class="!line-clamp-1">
              {{ Logic.Common.fomartDate(flight.departure_date, "dddd") }}
            </aero-pt-normal-text>
          </div>
        </div>

        <!-- Arrival -->
        <div
          class="col-span-1 py-3 px-3 flex flex-col space-y-1 justify-start pl-5"
        >
          <aero-pt-normal-text> Arrive </aero-pt-normal-text>
          <div class="flex flex-col space-y-[2px]">
            <aero-pt-header-text class="cursor-text">
              {{ Logic.Common.fomartDate(flight.arrival_date, "D MMMM, YYYY") }}
            </aero-pt-header-text>
            <aero-pt-normal-text class="!line-clamp-1">
              {{ Logic.Common.fomartDate(flight.arrival_date, "dddd") }}
            </aero-pt-normal-text>
          </div>
        </div>
      </div>

      <!-- Passenger -->
      <div
        class="col-span-1 grid grid-cols-1 border-[1px] border-[#E1E7EE] rounded-[12px]"
      >
        <div
          class="col-span-1 py-3 px-3 flex flex-col space-y-1 justify-start pl-5"
        >
          <aero-pt-normal-text> Passager </aero-pt-normal-text>
          <div class="flex flex-col space-y-[2px]">
            <aero-pt-header-text class="cursor-text">
              {{ flight.passengers.total }} Guest{{
                flight.passengers.total > 1 ? "s" : ""
              }}
            </aero-pt-header-text>
            <aero-pt-normal-text class="!line-clamp-1">
              {{ flight.bin_class }} class
            </aero-pt-normal-text>
          </div>
        </div>
      </div>
    </div>

    <!-- Search flight -->
    <div class="w-full flex flex-row items-center justify-center pt-2">
      <aero-pt-button :variant="'solid-blue'" :padding="'py-3 px-8'">
        Search Ticket
      </aero-pt-button>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive } from "vue";
import { AeroPtRadioGroup } from "../AeroPtForm";
import { AeroPtNormalText, AeroPtHeaderText } from "../AeroPtTypography";
import AeroPtButton from "../AeroPtButton";
import AeroPtIcon from "../AeroPtIcon";
import { Logic } from "../../composable";

export default defineComponent({
  components: {
    AeroPtRadioGroup,
    AeroPtNormalText,
    AeroPtHeaderText,
    AeroPtButton,
    AeroPtIcon,
  },
  props: {
    customClass: {
      type: String,
      default: "",
    },
  },
  name: "AeroPtSearch",
  setup() {
    const flightOptions = reactive([
      {
        label: "One Way",
        key: "OneWay",
      },
      {
        label: "Round Trip",
        key: "RoundTrip",
      },
      {
        label: "Multi City",
        key: "MultiCity",
      },
    ]);

    const flightSearchRequest = reactive({
      option: "",
      flights: [
        {
          from: {
            airport_code: "",
            airport_name: "Soekarno-Hatta Airport",
            location_name: "Jakarta",
          },
          to: {
            airport_code: "",
            airport_name: "John F. Kennedy International Airport",
            location_name: "New York",
          },
          departure_date: "2023-10-04",
          arrival_date: "2023-10-05",
          passengers: {
            total: 1,
            adults: 1,
            children: 0,
            infants: 0,
          },
          bin_class: "Economy",
        },
      ],
    });

    return {
      flightOptions,
      flightSearchRequest,
      Logic,
    };
  },
});
</script>
