<template>
  <div
    class="w-full flex flex-col space-y-2 rounded-[24px] bg-white cursor-pointer"
    v-if="data"
  >
    <aero-pt-image-loader
      :custom-class="'lg:!h-[200px] mdlg:!h-[160px] h-[120px] w-full rounded-t-[24px]'"
      :photoUrl="data.image_url"
    >
    </aero-pt-image-loader>
    <div class="w-full flex flex-col space-y-2 px-3 py-3">
      <aero-pt-header-text :size="'base'">
        {{ data.name }}
      </aero-pt-header-text>
      <div class="w-full flex flex-row items-center space-x-2">
        <div class="space-x-1 flex flex-row">
          <aero-pt-icon
            :name="'full-star'"
            :customClass="'h-[19px]'"
            v-for="index in data.ratings"
            :key="index"
          >
          </aero-pt-icon>
        </div>
        <aero-pt-normal-text>
          ({{ data.reviews }} reviews)
        </aero-pt-normal-text>
      </div>
      <div class="w-full flex flex-row space-x-2">
        <aero-pt-icon :name="'location'" :customClass="'h-[24px]'">
        </aero-pt-icon>
        <aero-pt-normal-text> New York, US </aero-pt-normal-text>
      </div>
      <div class="pt-2 flex flex-row w-full items-center justify-between">
        <div class="flex flex-row space-x-1 items-center">
          <aero-pt-header-text :size="'xl'">
            {{ data.price }}
          </aero-pt-header-text>
          <aero-pt-normal-text> night </aero-pt-normal-text>
        </div>

        <div class="flex flex-row">
          <aero-pt-icon
            :name="data.is_liked ? 'like-on' : 'like-off'"
            :customClass="'h-[36px] cursor-pointer'"
            @click="
              data.is_liked ? (data.is_liked = false) : (data.is_liked = true)
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import AeroPtImageLoader from "../AeroPtImageLoader";
import { AeroPtHeaderText, AeroPtNormalText } from "../AeroPtTypography";
import AeroPtIcon from "../AeroPtIcon";

export default defineComponent({
  components: {
    AeroPtImageLoader,
    AeroPtHeaderText,
    AeroPtNormalText,
    AeroPtIcon,
  },
  props: {
    customClass: {
      type: String,
      default: "",
    },
    data: {
      type: Object as () => {
        name: string;
        ratings: number;
        reviews: number;
        location: string;
        price: string;
        is_liked: boolean;
        image_url: string;
      },
    },
  },
  name: "AeroPtHotelCard",
});
</script>
