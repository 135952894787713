<template>
  <div
    class="w-full h-auto flex flex-col space-y-6 items-center justify-center"
  >
    <!-- CTA Section -->
    <div
      class="w-full flex flex-col h-[680px] relative items-center"
      style="background: rgba(88, 167, 255, 1)"
    >
      <img
        src="/images/cloud-bg.svg"
        class="w-full absolute lg:!bottom-[-16%] mdlg:!bottom-[-8%] left-0"
      />
      <img
        src="/images/bottom-curve.svg"
        class="w-full absolute bottom-[-10%] left-0"
      />

      <!-- CTA Contents -->
      <div class="media-width z-10 flex flex-col">
        <!-- Spacer -->
        <div class="h-[100px]"></div>
        <div
          class="w-full flex flex-col lg:!space-y-7 mdlg:!space-y-8 space-y-6 items-center justify-center"
        >
          <div class="lg:!w-[38%] mdlg:!w-[35%] w-[80%] flex flex-col pt-3">
            <aero-pt-switch :items="tabItems" />
          </div>

          <!-- CTA Text -->
          <aero-pt-header-text
            :color="'text-white'"
            class="lg:!text-[55px] mdlg:!text-[50px] text-[40px] leading-tight !font-extrabold lg:!w-[30%] mdlg:!w-[30%] w-[80%] text-center font-raleway"
          >
            Take Flight with Ease!
          </aero-pt-header-text>
        </div>
      </div>

      <div
        class="z-20 bg-neutral_background absolute bottom-[-20%] h-[100px] w-full left-0"
      ></div>

      <!-- Search Flight Section -->
      <div class="media-width flex flex-col absolute bottom-[-14%] z-[50]">
        <aero-pt-search />
      </div>
    </div>

    <!-- Spacer -->

    <div class="h-[100px]"></div>

    <!-- Promo Section -->
    <div class="media-width flex flex-col relative">
      <aero-pt-slider
        :free-mode="false"
        :space-between="20"
        :slide-per-view="3"
        :currentSlidePosition="currentPromo"
        custom-class="!h-full "
        :swiperClass="''"
        v-model="currentSlidePromoPosition"
      >
        <swiper-slide v-for="(item, index) in promoItems" :key="index">
          <aero-pt-image-loader
            :customClass="'w-full h-[230px] rounded-[24px] relative'"
            :photoUrl="item.image_url"
          >
            <aero-pt-normal-text
              class="absolute top-[6%] right-[3%] !bg-white rounded-[24px] py-1 px-3 !font-semibold !text-primary_blue"
            >
              {{ item.duration }}
            </aero-pt-normal-text>
          </aero-pt-image-loader>
        </swiper-slide>
      </aero-pt-slider>

      <!-- Paginators -->

      <div
        class="w-full flex flex-row items-center justify-center space-x-2 absolute left-0 bottom-[-14%]"
      >
        <span
          v-for="(page, index) in Logic.Common.groupArray(promoItems, 3)"
          :key="index"
          :class="`${
            index == currentPromo
              ? 'h-[11px] w-[48px] rounded-[15px] bg-primary_blue'
              : 'h-[12px] w-[12px] bg-neutral_grey_light rounded-[16px]'
          }`"
        >
        </span>
      </div>

      <!-- Left arrow -->
      <div
        class="absolute h-full left-[-2%] bottom-0 flex items-center justify-center z-50"
      >
        <span
          class="w-[46px] h-[46px] rounded-[46px] bg-white drop-shadow-md flex items-center justify-center cursor-pointer"
          @click="slidePromo('prev')"
        >
          <aero-pt-icon :name="'blue-chevron-left'" :customClass="'h-[30px]'" />
        </span>
      </div>

      <!-- Right arrow -->
      <div
        class="absolute h-full right-[-2%] bottom-0 flex items-center justify-center z-50"
      >
        <span
          class="w-[46px] h-[46px] rounded-[46px] bg-white drop-shadow-md flex items-center justify-center cursor-pointer"
          @click="slidePromo('next')"
        >
          <aero-pt-icon
            :name="'blue-chevron-right'"
            :customClass="'h-[30px]'"
          />
        </span>
      </div>
    </div>

    <!-- Spacer -->

    <div class="h-[10px]"></div>

    <!-- Hotel Recommendation Section -->
    <div class="media-width flex flex-col space-y-4">
      <aero-pt-header-text :size="'xl'" class="font-raleway !font-bold">
        Hotel Recomendation
      </aero-pt-header-text>

      <div class="w-full flex flex-col relative">
        <aero-pt-slider
          :free-mode="false"
          :space-between="20"
          :slide-per-view="4"
          :currentSlidePosition="currentHotel"
          custom-class="!h-full"
          :swiperClass="''"
          v-model="currentSlideHotelPosition"
          :uniqueKey="'hotel'"
          ref="hotelRecommendation"
        >
          <swiper-slide
            v-for="(item, index) in hotelRecommendations"
            :key="index"
          >
            <aero-pt-hotel-card :data="item"> </aero-pt-hotel-card>
          </swiper-slide>
        </aero-pt-slider>

        <!-- Left arrow -->
        <div
          class="absolute h-full left-[-2%] bottom-0 flex items-center justify-center z-50"
        >
          <span
            class="w-[46px] h-[46px] rounded-[46px] bg-white drop-shadow-md flex items-center justify-center cursor-pointer"
            @click="slideHotel('prev')"
          >
            <aero-pt-icon
              :name="'blue-chevron-left'"
              :customClass="'h-[30px]'"
            />
          </span>
        </div>

        <!-- Right arrow -->
        <div
          class="absolute h-full right-[-2%] bottom-0 flex items-center justify-center z-50"
        >
          <span
            class="w-[46px] h-[46px] rounded-[46px] bg-white drop-shadow-md flex items-center justify-center cursor-pointer"
            @click="slideHotel('next')"
          >
            <aero-pt-icon
              :name="'blue-chevron-right'"
              :customClass="'h-[30px]'"
            />
          </span>
        </div>
      </div>

      <div class="w-full flex flex-row items-center justify-center pt-2">
        <aero-pt-button :variant="'solid-blue'">
          View All packet
        </aero-pt-button>
      </div>
    </div>

    <!-- Spacer -->

    <div class="h-[50px]"></div>

    <div
      class="media-width flex flex-col h-[400px] relative items-center justify-center z-20"
    >
      <img src="/images/ticket-background.png" class="h-full w-full" />
    </div>

    <!-- Partner Airline -->
    <div
      class="w-full flex flex-col lg:!h-[2000px] mdlg:!h-[1820px] relative lg:!mt-[-17%] mdlg:!mt-[-23%] mt-[-23%]"
    >
      <img
        src="/images/partner-background.svg"
        class="w-full absolute top-0 left-0"
      />

      <img
        src="/images/bottom-cloud.svg"
        class="w-full absolute bottom-[-10%] left-0"
      />

      <!-- Spacer -->

      <div class="h-[330px]"></div>

      <div
        class="w-full flex flex-col space-y-4 items-center justify-center z-50 overflow-y-hidden"
      >
        <div class="media-width flex flex-col space-y-3">
          <div class="w-full flex flex-row items-center justify-between">
            <div class="flex flex-col space-y-2 w-[30%]">
              <aero-pt-header-text
                class="font-raleway !font-bold !text-white lg:!text-3xl mdlg:!text-2xl text-xl"
              >
                Partner Airlines
              </aero-pt-header-text>
              <aero-pt-normal-text :color="'text-white'">
                Through our extensive collaboration with more than 200 leading
                airlines worldwide connecting you to any destination you want
                between countries and cities
              </aero-pt-normal-text>
            </div>

            <div class="w-full flex flex-row justify-end">
              <img src="/images/plane.svg" class="h-[200px]" />
            </div>
          </div>

          <div class="w-full flex flex-row items-center justify-center pt-5">
            <img src="/images/all-airlines.svg" class="h-[110px]" />
          </div>
        </div>

        <!-- Spacer -->

        <div class="h-[380px]"></div>

        <div
          class="media-width flex flex-col space-y-6 justify-center items-center"
        >
          <aero-pt-header-text
            class="font-raleway !font-bold w-[20%] !text-3xl text-center"
          >
            Frequently Asked Questions
          </aero-pt-header-text>

          <div class="w-full grid grid-cols-3 gap-6">
            <div
              class="col-span-1 bg-white rounded-[12px] flex flex-col"
              v-for="(item, index) in FAQs"
              :key="index"
            >
              <div
                class="w-full py-4 px-4 flex flex-row border-b-[1px] border-neutral_grey_light"
              >
                <aero-pt-header-text class="!font-normal">
                  {{ item.question }}
                </aero-pt-header-text>
              </div>
              <div class="w-full py-4 px-4">
                <aero-pt-normal-text class="text-left">
                  {{ item.answer }}
                </aero-pt-normal-text>
              </div>
            </div>
          </div>

          <div
            class="w-full flex flex-col items-center justify-center space-y-2 pt-4"
          >
            <aero-pt-normal-text :color="'text-black'">
              Need more question?
            </aero-pt-normal-text>
            <aero-pt-button :variant="'solid-blue'">
              Contact Us
            </aero-pt-button>
          </div>
        </div>
      </div>

      <div class="w-full flex flex-col z-50 absolute bottom-[-10%] left-0">
        <aero-pt-bottom-nav />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import { useMeta } from "vue-meta";
import {
  AeroPtSwitch,
  AeroPtHeaderText,
  AeroPtSearch,
  AeroPtSlider,
  AeroPtImageLoader,
  AeroPtNormalText,
  AeroPtIcon,
  AeroPtHotelCard,
  AeroPtButton,
  AeroPtBottomNav,
} from "@aeropt/ui-components";
import { SwiperSlide } from "swiper/vue";
import { Logic } from "@aeropt/logic";

export default defineComponent({
  name: "Index",
  layout: "Home",
  components: {
    AeroPtSwitch,
    AeroPtHeaderText,
    AeroPtSearch,
    SwiperSlide,
    AeroPtSlider,
    AeroPtImageLoader,
    AeroPtNormalText,
    AeroPtIcon,
    AeroPtHotelCard,
    AeroPtButton,
    AeroPtBottomNav,
  },
  setup() {
    useMeta({
      title: "Home",
    });

    const currentPromo = ref(0);
    const currentSlidePromoPosition = ref(0);

    const currentHotel = ref(0);
    const currentSlideHotelPosition = ref(0);

    const hotelRecommendation = ref();

    const tabItems = reactive([
      {
        name: "Airplane Ticket",
        key: "airplane_ticket",
      },
      {
        name: "Airport Shuttle",
        key: "airport_shuttle",
      },
      {
        name: "Booking Hotel",
        key: "booking_hotel",
      },
    ]);

    const promoItems = reactive([
      {
        action: () => {
          //
        },
        image_url: "/images/promo-1.png",
        duration: "12 - 24 Jul",
      },
      {
        action: () => {
          //
        },
        image_url: "/images/promo-2.png",
        duration: "12 - 24 Jul",
      },
      {
        action: () => {
          //
        },
        image_url: "/images/promo-3.png",
        duration: "12 - 24 Jul",
      },
      {
        action: () => {
          //
        },
        image_url: "/images/promo-2.png",
        duration: "12 - 24 Jul",
      },
      {
        action: () => {
          //
        },
        image_url: "/images/promo-3.png",
        duration: "12 - 24 Jul",
      },
      {
        action: () => {
          //
        },
        image_url: "/images/promo-2.png",
        duration: "12 - 24 Jul",
      },
      {
        action: () => {
          //
        },
        image_url: "/images/promo-1.png",
        duration: "12 - 24 Jul",
      },
      {
        action: () => {
          //
        },
        image_url: "/images/promo-3.png",
        duration: "12 - 24 Jul",
      },
    ]);

    const slidePromo = (type: "next" | "prev") => {
      const max = Logic.Common.groupArray(promoItems, 3).length - 1;
      const min = 0;

      if (type == "next") {
        if (currentSlidePromoPosition.value < max) {
          currentPromo.value++;
        }
      }

      if (type == "prev") {
        if (currentSlidePromoPosition.value > min) {
          currentPromo.value--;
        }
      }
    };

    const slideHotel = (type: "next" | "prev") => {
      const hotelSliderComponent = hotelRecommendation.value;

      if (type == "next") {
        hotelSliderComponent.nextSlide();
      } else {
        hotelSliderComponent.prevSlide();
      }
    };

    const hotelRecommendations = reactive([
      {
        name: "Serene Retreat",
        ratings: 5,
        reviews: 112,
        location: "New York, US",
        price: "$352",
        is_liked: true,
        image_url: "/images/hotel-1.png",
      },
      {
        name: "Emerald Lodge",
        ratings: 5,
        reviews: 212,
        location: "New York, US",
        price: "$499",
        is_liked: false,
        image_url: "/images/hotel-2.png",
      },
      {
        name: "Serene Retreat",
        ratings: 5,
        reviews: 112,
        location: "New York, US",
        price: "$1,232",
        is_liked: false,
        image_url: "/images/hotel-3.png",
      },
      {
        name: "Blissful Heights",
        ratings: 5,
        reviews: 100,
        location: "New York, US",
        price: "$421",
        is_liked: false,
        image_url: "/images/hotel-4.png",
      },
      {
        name: "Emerald Lodge",
        ratings: 5,
        reviews: 212,
        location: "New York, US",
        price: "$1,232",
        is_liked: false,
        image_url: "/images/hotel-2.png",
      },
      {
        name: "Serene Retreat",
        ratings: 5,
        reviews: 112,
        location: "New York, US",
        price: "$352",
        is_liked: true,
        image_url: "/images/hotel-1.png",
      },
    ]);

    const FAQs = reactive([
      {
        question: "How do I book a flight or hotel on Flywere?",
        answer:
          "Visit our site, enter travel details, choose options, and proceed to checkout for a seamless booking experience.",
      },
      {
        question: "How do I book a flight or hotel on Flywere?",
        answer:
          "Visit our site, enter travel details, choose options, and proceed to checkout for a seamless booking experience.",
      },
      {
        question: "How do I book a flight or hotel on Flywere?",
        answer:
          "Visit our site, enter travel details, choose options, and proceed to checkout for a seamless booking experience.",
      },
      {
        question: "How do I book a flight or hotel on Flywere?",
        answer:
          "Visit our site, enter travel details, choose options, and proceed to checkout for a seamless booking experience.",
      },
      {
        question: "How do I book a flight or hotel on Flywere?",
        answer:
          "Visit our site, enter travel details, choose options, and proceed to checkout for a seamless booking experience.",
      },
      {
        question: "How do I book a flight or hotel on Flywere?",
        answer:
          "Visit our site, enter travel details, choose options, and proceed to checkout for a seamless booking experience.",
      },
    ]);

    return {
      tabItems,
      promoItems,
      currentPromo,
      currentSlidePromoPosition,
      Logic,
      hotelRecommendations,
      currentHotel,
      currentSlideHotelPosition,
      hotelRecommendation,
      FAQs,
      slidePromo,
      slideHotel,
    };
  },
});
</script>
<style scoped></style>
