<template>
  <button
    :class="`focus:outline-none flex flex-row space-x-1 items-center justify-center font-semibold lg:text-sm mdlg:text-[12px] text-xs  rounded-[999px] ${padding} ${customClass} ${buttonStyle}`"
  >
    <slot />
  </button>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref, toRef, watch } from "vue";
export default defineComponent({
  props: {
    padding: {
      type: String,
      default: "px-5 py-1",
    },
    customClass: {
      type: String,
      default: "",
    },
    variant: {
      type: String as () =>
        | "outline-white"
        | "solid-white"
        | "solid-blue"
        | "outline-blue",
      default: "outline-white",
    },
  },
  name: "AeroPtButton",
  setup(props) {
    const buttonStyle = ref("");
    const variantRef = toRef(props, "variant");

    const setButtonSet = () => {
      // Set style
      switch (props.variant) {
        case "outline-white":
          buttonStyle.value =
            "text-white border-[1px] bg-transparent border-white";
          break;
        case "outline-blue":
          buttonStyle.value =
            "text-primary_blue border-[1px] bg-transparent border-primary_blue";
          break;
        case "solid-white":
          buttonStyle.value =
            "text-primary_blue border-[1px] border-white bg-white";
          break;
        case "solid-blue":
          buttonStyle.value =
            "text-white border-[1px] border-primary_blue bg-primary_blue";
          break;

        default:
          break;
      }
    };

    watch(variantRef, () => {
      setButtonSet();
    });

    onMounted(() => {
      setButtonSet();
    });

    return {
      buttonStyle,
    };
  },
});
</script>
