import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex w-full flex-col space-y-2 px-3" }
const _hoisted_2 = { class: "w-full grid grid-cols-12 gap-6" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "col-span-4 flex flex-row items-center justify-center" }
const _hoisted_5 = { class: "w-[43px] h-[43px] rounded-full border-[1px] border-transparent hover:bg-gray-50 flex flex-row items-center justify-center" }
const _hoisted_6 = { class: "col-span-4 flex flex-row items-center justify-center" }
const _hoisted_7 = { class: "col-span-4 flex flex-row items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_aero_pt_normal_text = _resolveComponent("aero-pt-normal-text")!
  const _component_aero_pt_icon = _resolveComponent("aero-pt-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Array.from(Array(9).keys()), (key, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "col-span-4 flex flex-row items-center justify-center",
          key: index
        }, [
          _createElementVNode("span", {
            onClick: ($event: any) => ($setup.content += `${key + 1}`),
            class: "w-[43px] h-[43px] rounded-full border-[1px] border-transparent hover:bg-gray-50 flex flex-row items-center justify-center"
          }, [
            _createVNode(_component_aero_pt_normal_text, { customClass: "!text-lg" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(key + 1), 1)
              ]),
              _: 2
            }, 1024)
          ], 8, _hoisted_3)
        ]))
      }), 128)),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", _hoisted_5, [
          ($props.hasFingerPrint)
            ? (_openBlock(), _createBlock(_component_aero_pt_icon, {
                key: 0,
                name: 'fingerprint',
                customClass: 'h-[30px]'
              }))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("span", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.content += `0`)),
          class: "w-[43px] h-[43px] rounded-full border-[1px] border-transparent hover:bg-gray-50 flex flex-row items-center justify-center"
        }, [
          _createVNode(_component_aero_pt_normal_text, { customClass: "!text-lg" }, {
            default: _withCtx(() => [
              _createTextVNode(" 0 ")
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("span", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.content = `${$setup.content.slice(0, -1)}`)),
          class: "w-[43px] h-[43px] rounded-full border-[1px] border-transparent hover:bg-gray-50 flex flex-row items-center justify-center"
        }, [
          _createVNode(_component_aero_pt_icon, {
            name: 'chevron-left-gray',
            customClass: 'h-[15px]'
          })
        ])
      ])
    ])
  ]))
}