<template>
  <div :class="`w-full flex flex-col ${wrapperStyles} items-center`">
    <div
      class="media-width flex flex-row items-center justify-between py-5 px-4"
    >
      <img :src="`/images/logo-variant-${logoVariant}.svg`" class="h-[28px]" />

      <div class="flex flex-row items-center space-x-4">
        <aero-pt-normal-text
          :color="` ${
            logoVariant == '1' ? 'text-neutral_white' : 'text-black'
          }`"
          class="cursor-pointer hover:underline"
        >
          Service
        </aero-pt-normal-text>
        <aero-pt-normal-text
          :color="` ${
            logoVariant == '1' ? 'text-neutral_white' : 'text-black'
          }`"
          class="cursor-pointer hover:underline"
        >
          About Us
        </aero-pt-normal-text>
        <aero-pt-normal-text
          :color="` ${
            logoVariant == '1' ? 'text-neutral_white' : 'text-black'
          }`"
          class="cursor-pointer hover:underline"
        >
          Contact
        </aero-pt-normal-text>
      </div>

      <div class="flex flex-row space-x-4 items-center">
        <aero-pt-button
          :variant="`${logoVariant == '1' ? 'outline-white' : 'outline-blue'}`"
        >
          Log in
        </aero-pt-button>
        <aero-pt-button
          :variant="`${logoVariant == '1' ? 'solid-white' : 'solid-blue'}`"
        >
          Sign in
        </aero-pt-button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import AeroPtButton from "../AeroPtButton";
import { AeroPtNormalText } from "../AeroPtTypography";

export default defineComponent({
  components: {
    AeroPtNormalText,
    AeroPtButton,
  },
  props: {
    customClass: {
      type: String,
      default: "",
    },
    color_theme: {
      type: String,
      default: "transparent",
    },
  },

  name: "AeroPtTopNav",
  setup(props) {
    const logoVariant = ref("1");
    const wrapperStyles = ref("bg-transparent");

    onMounted(() => {
      window.addEventListener("scroll", (evt) => {
        const currentScrollTop = document.documentElement.scrollTop;
        if (props.color_theme == "transparent") {
          if (currentScrollTop <= 46) {
            wrapperStyles.value = "bg-transparent";
            logoVariant.value = "1";
          } else {
            wrapperStyles.value = "bg-white shadow-custom_1";
            logoVariant.value = "2";
          }
        }
      });
    });

    return {
      logoVariant,
      wrapperStyles,
    };
  },
});
</script>
