import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex w-full flex-col space-y-2" }
const _hoisted_2 = { class: "flex flex-row space-x-2 items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_aero_pt_icon = _resolveComponent("aero-pt-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.selected ? ($setup.selected = false) : ($setup.selected = true))),
      class: "flex w-full flex-row space-x-2 items-center cursor-pointer"
    }, [
      _createVNode(_component_aero_pt_icon, {
        name: `${$setup.selected ? 'checkbox-active' : 'checkbox'}`,
        customClass: `h-[17px]`
      }, null, 8, ["name"]),
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "label")
      ])
    ])
  ]))
}