<template>
  <div
    class="flex flex-row w-full space-x-1 px-1 py-1 bg-white bg-opacity-20 rounded-[999px] justify-between"
  >
    <div
      v-for="(item, index) in items"
      :key="index"
      :class="`rounded-[999px] items-center justify-center flex flex-row lg:!px-5 mdlg:!px-5 px-4 py-2 cursor-pointer ${
        currentSelectedItem == item.key ? 'bg-white' : ''
      }`"
      @click="currentSelectedItem = item.key"
    >
      <aero-pt-normal-text
        :color="`${
          currentSelectedItem == item.key ? 'text-primary_blue' : 'text-white'
        }`"
        :customClass="`${
          currentSelectedItem == item.key ? '!font-semibold' : ''
        }`"
      >
        {{ item.name }}
      </aero-pt-normal-text>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { AeroPtNormalText } from "../AeroPtTypography";
export default defineComponent({
  components: {
    AeroPtNormalText,
  },
  props: {
    customClass: {
      type: String,
      required: false,
    },
    items: {
      type: Array as () => {
        name: string;
        key: string;
      }[],
      default: () => [],
    },
  },
  name: "AeroPtSwitch",
  setup(props) {
    const currentSelectedItem = ref("");

    onMounted(() => {
      if (props.items.length) {
        currentSelectedItem.value = props.items[0].key;
      }
    });

    return {
      currentSelectedItem,
    };
  },
});
</script>
