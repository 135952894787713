<template>
  <h3
    :class="` font-semibold 
	${size == 'lg' ? 'lg:text-lg mdlg:text-lg text-lg' : ''}
	${size == 'xl' ? 'lg:text-xl mdlg:text-xl text-base' : ''}
	${size == 'base' ? 'lg:text-base mdlg:text-[13px] text-sm' : ''}
	${size == 'xs' ? 'mdlg:!text-[12px] text-xs' : ''}
	${customClass}
	${color}`"
  >
    <slot />
  </h3>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    size: {
      type: String,
      default: "lg",
    },
    color: {
      type: String,
      default: "text-neutral_black",
    },
    customClass: {
      type: String,
      default: "",
    },
  },
  name: "AeroPtHeaderText",
});
</script>
