import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "media-width flex flex-row items-center justify-between py-5 px-4" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "flex flex-row items-center space-x-4" }
const _hoisted_4 = { class: "flex flex-row space-x-4 items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_aero_pt_normal_text = _resolveComponent("aero-pt-normal-text")!
  const _component_aero_pt_button = _resolveComponent("aero-pt-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`w-full flex flex-col ${_ctx.wrapperStyles} items-center`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: `/images/logo-variant-${_ctx.logoVariant}.svg`,
        class: "h-[28px]"
      }, null, 8, _hoisted_2),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_aero_pt_normal_text, {
          color: ` ${
            _ctx.logoVariant == '1' ? 'text-neutral_white' : 'text-black'
          }`,
          class: "cursor-pointer hover:underline"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Service ")
          ]),
          _: 1
        }, 8, ["color"]),
        _createVNode(_component_aero_pt_normal_text, {
          color: ` ${
            _ctx.logoVariant == '1' ? 'text-neutral_white' : 'text-black'
          }`,
          class: "cursor-pointer hover:underline"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" About Us ")
          ]),
          _: 1
        }, 8, ["color"]),
        _createVNode(_component_aero_pt_normal_text, {
          color: ` ${
            _ctx.logoVariant == '1' ? 'text-neutral_white' : 'text-black'
          }`,
          class: "cursor-pointer hover:underline"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Contact ")
          ]),
          _: 1
        }, 8, ["color"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_aero_pt_button, {
          variant: `${_ctx.logoVariant == '1' ? 'outline-white' : 'outline-blue'}`
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Log in ")
          ]),
          _: 1
        }, 8, ["variant"]),
        _createVNode(_component_aero_pt_button, {
          variant: `${_ctx.logoVariant == '1' ? 'solid-white' : 'solid-blue'}`
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Sign in ")
          ]),
          _: 1
        }, 8, ["variant"])
      ])
    ])
  ], 2))
}