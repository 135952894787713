<template>
  <div
    :class="`w-full flex flex-col bg-white items-center border-t-[1px] border-neutral_grey_light space-y-4`"
  >
    <div
      class="media-width flex flex-row items-center justify-between py-5 px-4"
    >
      <div class="flex flex-col space-y-4 w-[25%]">
        <img :src="`/images/logo-large.svg`" class="h-[45px]" />
        <aero-pt-normal-text>
          Flight Expert Indonesia (Jakarta) <br />
          <br />
          Jl. Raya Bandara Soekarno-Hatta, Tangerang, Banten, Indonesia.
        </aero-pt-normal-text>
      </div>

      <div class="w-full flex flex-row justify-end items-start space-x-14">
        <!-- About us -->
        <div class="flex flex-col space-y-4">
          <aero-pt-normal-text :color="'text-black'">
            About
          </aero-pt-normal-text>

          <div class="flex flex-col space-y-2 items-start">
            <aero-pt-normal-text class="hover:underline cursor-pointer">
              About Us
            </aero-pt-normal-text>
            <aero-pt-normal-text class="hover:underline cursor-pointer">
              Our Services
            </aero-pt-normal-text>
            <aero-pt-normal-text class="hover:underline cursor-pointer">
              Cookie Policy
            </aero-pt-normal-text>
            <aero-pt-normal-text class="hover:underline cursor-pointer">
              Privacy Policy
            </aero-pt-normal-text>
          </div>
        </div>

        <div class="flex flex-col space-y-4">
          <aero-pt-normal-text :color="'text-black'">
            Help
          </aero-pt-normal-text>

          <div class="flex flex-col space-y-2 items-start">
            <aero-pt-normal-text class="hover:underline cursor-pointer">
              FAQ
            </aero-pt-normal-text>
            <aero-pt-normal-text class="hover:underline cursor-pointer">
              Payment methods
            </aero-pt-normal-text>
            <aero-pt-normal-text class="hover:underline cursor-pointer">
              Contact us
            </aero-pt-normal-text>
          </div>
        </div>

        <div class="flex flex-col space-y-4">
          <aero-pt-normal-text :color="'text-black'">
            Social
          </aero-pt-normal-text>

          <div class="flex flex-col space-y-2 items-start">
            <aero-pt-normal-text class="hover:underline cursor-pointer">
              Twitter
            </aero-pt-normal-text>
            <aero-pt-normal-text class="hover:underline cursor-pointer">
              Linkedin
            </aero-pt-normal-text>
            <aero-pt-normal-text class="hover:underline cursor-pointer">
              Facebook
            </aero-pt-normal-text>
            <aero-pt-normal-text class="hover:underline cursor-pointer">
              Youtube
            </aero-pt-normal-text>
          </div>
        </div>
      </div>
    </div>

    <div
      class="w-full flex flex-row items-center justify-center py-4 bg-[#FAFBFD]"
    >
      <aero-pt-normal-text>
        ©2023 Flywere. All rights reserved
      </aero-pt-normal-text>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import AeroPtButton from "../AeroPtButton";
import { AeroPtNormalText } from "../AeroPtTypography";

export default defineComponent({
  components: {
    AeroPtNormalText,
    AeroPtButton,
  },
  props: {
    customClass: {
      type: String,
      default: "",
    },
    color_theme: {
      type: String,
      default: "transparent",
    },
  },

  name: "AeroPtBottomNav",
  setup() {
    return {};
  },
});
</script>
