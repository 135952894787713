import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full flex flex-col space-y-2 rounded-[24px] bg-white cursor-pointer"
}
const _hoisted_2 = { class: "w-full flex flex-col space-y-2 px-3 py-3" }
const _hoisted_3 = { class: "w-full flex flex-row items-center space-x-2" }
const _hoisted_4 = { class: "space-x-1 flex flex-row" }
const _hoisted_5 = { class: "w-full flex flex-row space-x-2" }
const _hoisted_6 = { class: "pt-2 flex flex-row w-full items-center justify-between" }
const _hoisted_7 = { class: "flex flex-row space-x-1 items-center" }
const _hoisted_8 = { class: "flex flex-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_aero_pt_image_loader = _resolveComponent("aero-pt-image-loader")!
  const _component_aero_pt_header_text = _resolveComponent("aero-pt-header-text")!
  const _component_aero_pt_icon = _resolveComponent("aero-pt-icon")!
  const _component_aero_pt_normal_text = _resolveComponent("aero-pt-normal-text")!

  return (_ctx.data)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_aero_pt_image_loader, {
          "custom-class": 'lg:!h-[200px] mdlg:!h-[160px] h-[120px] w-full rounded-t-[24px]',
          photoUrl: _ctx.data.image_url
        }, null, 8, ["photoUrl"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_aero_pt_header_text, { size: 'base' }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.data.name), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.ratings, (index) => {
                return (_openBlock(), _createBlock(_component_aero_pt_icon, {
                  name: 'full-star',
                  customClass: 'h-[19px]',
                  key: index
                }))
              }), 128))
            ]),
            _createVNode(_component_aero_pt_normal_text, null, {
              default: _withCtx(() => [
                _createTextVNode(" (" + _toDisplayString(_ctx.data.reviews) + " reviews) ", 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_aero_pt_icon, {
              name: 'location',
              customClass: 'h-[24px]'
            }),
            _createVNode(_component_aero_pt_normal_text, null, {
              default: _withCtx(() => [
                _createTextVNode(" New York, US ")
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_aero_pt_header_text, { size: 'xl' }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.data.price), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_aero_pt_normal_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(" night ")
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_aero_pt_icon, {
                name: _ctx.data.is_liked ? 'like-on' : 'like-off',
                customClass: 'h-[36px] cursor-pointer',
                onClick: _cache[0] || (_cache[0] = ($event: any) => (
              _ctx.data.is_liked ? (_ctx.data.is_liked = false) : (_ctx.data.is_liked = true)
            ))
              }, null, 8, ["name"])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}