import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center space-x-4" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_aero_pt_icon = _resolveComponent("aero-pt-icon")!
  const _component_aero_pt_normal_text = _resolveComponent("aero-pt-normal-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "flex flex-row space-x-2 items-center cursor-pointer",
        onClick: ($event: any) => (_ctx.selectedOption = item.key)
      }, [
        _createVNode(_component_aero_pt_icon, {
          name: item.key == _ctx.selectedOption ? `radio-on` : `radio-off`,
          customClass: 'h-[19px]'
        }, null, 8, ["name"]),
        _createVNode(_component_aero_pt_normal_text, {
          color: `${
          item.key == _ctx.selectedOption ? 'text-black' : 'text-neutral_grey_1'
        }`
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.label), 1)
          ]),
          _: 2
        }, 1032, ["color"])
      ], 8, _hoisted_2))
    }), 128))
  ]))
}