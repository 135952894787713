<template>
  <component :is="layout">
    <slot />
  </component>
</template>

<script lang="ts">
import AppLayoutDefault from "./AppLayoutDefault.vue";
import Sample from "./Sample.vue";
import Home from "./Home.vue";
import { onMounted, shallowRef, watch } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "AppLayout",
  setup() {
    const layout = shallowRef(AppLayoutDefault);
    const route = useRoute();

    const setPageLayout = (meta: any) => {
      try {
        if (meta.layout == "Sample") layout.value = Sample;
        if (meta.layout == "Home") layout.value = Home;
      } catch (e) {
        layout.value = AppLayoutDefault;
      }
    };
    watch(
      () => route.meta,
      (meta) => {
        setPageLayout(meta);
      },
      { immediate: true }
    );

    onMounted(() => {
      const meta = route.meta;
      setPageLayout(meta);
    });
    return { layout };
  },
};
</script>
