<template>
  <div :class="`w-full col-span-full ${customClass} `">
    <swiper
      :slides-per-view="slidePerView"
      :space-between="spaceBetween"
      @swiper="onSwiper"
      :freeMode="freeMode"
      :modules="modules"
      @slideChange="onSlideChange"
      :class="swiperClass"
      :direction="direction"
    >
      <slot />
    </swiper>
  </div>
</template>
<script lang="ts">
// Import Swiper Vue.js components
import { Swiper } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";

// import required modules
import { FreeMode } from "swiper";
import { defineComponent, ref, toRef, watch } from "vue";

export default defineComponent({
  components: {
    Swiper,
  },
  props: {
    customClass: {
      type: String,
      default: "",
    },
    slideCount: {
      type: Number,
      default: 3,
    },
    showPagination: {
      type: Boolean,
      default: false,
    },
    slidePerView: {
      type: String as () => any,
      default: "auto",
    },
    spaceBetween: {
      type: Number,
      default: 15,
    },
    freeMode: {
      type: Boolean,
      default: true,
    },
    swiperClass: {
      type: String,
      default: "pr-6",
    },
    currentSlidePosition: {
      type: Number,
      default: 0,
    },
    direction: {
      type: String as () => "vertical" | "horizontal",
      default: "horizontal",
    },
    uniqueKey: {
      type: String,
      default: "",
    },
  },
  name: "AeroPtSlider",
  emits: ["update:modelValue"],
  setup(props: any, context: any) {
    const activeSlide = ref(0);
    // eslint-disable-next-line @typescript-eslint/no-empty-function

    const swiperInstance = ref<any>();
    const onSwiper = (swiper: any) => {
      swiperInstance.value = swiper;
    };
    const onSlideChange = (swiper: any) => {
      activeSlide.value = swiper.activeIndex;
      context.emit("update:modelValue", activeSlide.value);
    };

    const currentSlidePositionRef = toRef(props, "currentSlidePosition");

    watch(currentSlidePositionRef, () => {
      swiperInstance.value.slideTo(currentSlidePositionRef.value);
    });

    const nextSlide = () => {
      swiperInstance.value.slideNext();
    };

    const prevSlide = () => {
      swiperInstance.value.slidePrev();
    };

    return {
      onSwiper,
      onSlideChange,
      nextSlide,
      prevSlide,
      modules: [FreeMode],
      swiperInstance,
      activeSlide,
    };
  },
});
</script>
