import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("h3", {
    class: _normalizeClass(` font-semibold 
	${_ctx.size == 'lg' ? 'lg:text-lg mdlg:text-lg text-lg' : ''}
	${_ctx.size == 'xl' ? 'lg:text-xl mdlg:text-xl text-base' : ''}
	${_ctx.size == 'base' ? 'lg:text-base mdlg:text-[13px] text-sm' : ''}
	${_ctx.size == 'xs' ? 'mdlg:!text-[12px] text-xs' : ''}
	${_ctx.customClass}
	${_ctx.color}`)
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}